import React from 'react'
import Navbar from '../components/Navbar'
import { Footer } from '../components/Footer'

export const Pricing = () => {
  return (
    <div>
      <Navbar />
      <Footer /> 
    </div>
  )
}

export default Pricing
