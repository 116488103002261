import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './NavbarStyles.css'
import {FaBars, FaTimes} from 'react-icons/fa'

const Navbar = () => {
    const [click , setClick] = useState(false)
    const handleClick = () => setClick(!click)
  return (
    <div className='header'>
      <Link to=''><h1>Oiii Restaurant</h1></Link>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
            <Link to='/'>About Us</Link>
        </li>
        <li>
            <Link to='/pricing'>Foods</Link>
        </li>
        <li>
            <Link to='/training'>Discounts</Link>
        </li>
        <li>
            <Link to='/delivery'>Delivery</Link>
        </li>
        <li>
            <Link to='/contact'>Contact Us</Link>
        </li>
      </ul>
      <div className='hamburger' onClick={handleClick}>
        {click ? (<FaTimes size ={20} style={{color :'#fff'}} />) :(<FaBars size ={20} style={{color :'#fff'}}/>)}
      </div>
    </div>
  )
}

export default Navbar